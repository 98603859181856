@import "https://fonts.googleapis.com/css?family=Quicksand:400,500,700";
body {
  font-family: Quicksand, sans-serif;
}

.content-wrap {
  background: #fff;
  position: relative;
  box-shadow: 0 0 50px 5px rgba(0, 0, 0, .15);
}

.bg-gray {
  background: #f8f8f8;
}

.section-title {
  margin-bottom: 25px;
  font-weight: 500;
  position: relative;
}

.section-title:after {
  content: "";
  width: 80px;
  height: 3px;
  background: #000;
  margin-top: 10px;
  position: absolute;
  top: 100%;
  left: 0;
}

.section-title.text-center:after {
  left: calc(50% - 80px);
}

#about {
  background: url("bg.79807abc.jpg") 0 0 / cover no-repeat;
}

#about > .row {
  background-color: rgba(0, 0, 0, .5);
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track-piece {
  background-clip: padding-box;
  margin-top: 1px;
}

::-webkit-scrollbar-thumb {
  background-color: #131619;
  border-color: #36393e;
}

::-webkit-scrollbar-track-piece {
  background-color: #2f3136;
  border-color: #36393e;
}

.badge {
  white-space: normal;
}

@media (max-width: 350px) {
  .section-title {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.4rem;
  }
}

/*# sourceMappingURL=index.54239de7.css.map */
