@import url('https://fonts.googleapis.com/css?family=Quicksand:400,500,700');

body {
  font-family: 'Quicksand', sans-serif;
}

.content-wrap {
  -webkit-box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.15);
  position: relative;
  background: #fff;
}

.bg-gray {
  background: #f8f8f8;
}


.section-title {
  position: relative;
  margin-bottom: 25px;
  font-weight: 500;

  &:after {
    content: '';
    position: absolute;
    width: 80px;
    height: 3px;
    background: #000;
    top: 100%;
    left:0;
    margin-top: 10px;
  }

  &.text-center {
    &:after {
      left: 50%;
      left: calc(50% - 80px);
    }
  }
}

#about {
  background: url("../img/bg.jpg") no-repeat;
  background-size: cover;
  > .row {
    background-color: rgba(0,0,0,0.5);
  }
}


::-webkit-scrollbar {
  width: 14px;
}
::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track-piece {
  background-clip:padding-box;
  //border: 3px solid;
  //border-radius: 7px;
  margin-top: 1px;
}
::-webkit-scrollbar-thumb {
  background-color: #131619;
  border-color: #36393e;
}
::-webkit-scrollbar-track-piece {
  background-color: #2f3136;
  border-color: #36393e;
}

.badge {
  white-space: normal;
}

@media(max-width: 350px) {
  .section-title {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.4rem;
  }
}
